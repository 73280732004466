export const fashionDivision = (base = "Третьяковский%20проезд") => ({
  title: "Одежда, обувь, аксессуары",
  brands: [],

  init() {
    fetch(`https://data.tretyakovsky.ru/api/brands?bases[]=${base}`)
      .then((res) => res.json())
      .then((brands) => {
        this.brands = brands
          .map(({ name, bases: [{ phone, phoneMan, phoneWoman }] }) => {
            return phoneMan && phoneWoman && phoneMan !== phoneWoman
              ? [
                  {
                    name: `${name}<br><span style="font-size: 13px">(мужская коллекция)</span>`,
                    phone: phoneMan,
                  },
                  {
                    name: `${name}<br><span style="font-size: 13px">(женская коллекция)</span>`,
                    phone: phoneWoman,
                  },
                ]
              : [
                  {
                    name,
                    phone,
                  },
                ];
          })
          .flat()
          .filter(({ phone, name }) => !!phone && !/Couture/.test(name))
          .map(({ name, phone }) => ({
            name,
            href: `+${phone.replace(/\D/g, "")}`,
            phone: phone.replace(/(\d\d\d)(\d\d\d)(\d\d\d\d)$/, " ($1) $2 $3"),
          }));
      });
  },
});
